import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import { twMerge } from 'tailwind-merge'

import BannerLink from '../BannerLink'

import Image from '@sholdi/primitives/atoms/Image'
import Text from '@sholdi/primitives/atoms/Text'
import Link from '@sholdi/primitives/atoms/Link'
import Button from '@sholdi/primitives/atoms/Button'

import GallerySkeleton from './GallerySkeleton'
import ProductListSkeleton from './ProductListSkeleton'
import CardListSkeleton from './CardListSkeleton'
import GridSkeleton from './GridSkeleton'
import PopularCategoriesSkeleton from './PopularCategoriesSkeleton'

const GridLayout = dynamic(() => import('../../molecules/GridLayout'), {
  loading: GridSkeleton,
})

const BannerLinksGallery = dynamic(() => import('../../molecules/BannerLinksGallery'), {
  loading: GallerySkeleton,
})

const ProductList = dynamic(() => import('../ProductList'), {
  loading: ProductListSkeleton,
})

const ListHeader = dynamic(() => import('../../molecules/ListHeader'))
const CardList = dynamic(() => import('../CardList'), {
  loading: CardListSkeleton,
})
const PopularCategories = dynamic(() => import('./PopularCategories'), {
  loading: PopularCategoriesSkeleton,
})

const PageBanners = ({ sections = [], ...props }) => (
  <div className="flex flex-col w-full gap-14 lg:gap-12 pt-6">
    {sections.map((section, i) => {
      const isImageTextBlock = section.type === 'imageTextBlock'
      const fluidClass = !section.fluid
        ? 'container mx-auto'
        : isImageTextBlock
          ? ''
          : 'px-4'

      if (section.type === 'imageTextBlock') {
        return (
          <div
            key={i}
            className={twMerge(
              'w-full flex items-center flex-col md:flex-row my-0 md:my-10 gap-8',
              section.direction === 'rtl' ? 'md:flex-row-reverse' : '',
              fluidClass,
            )}
          >
            <Image
              src={section.sectionImage}
              alt={section.sectionTitle}
              className={twMerge('h-full flex-1', section.imageSize ?? 'h-120')}
              objectFit={fluidClass ? 'contain' : 'cover'}
            />
            <div
              className={twMerge(
                'w-full md:w-1/2 flex-1',
                section.direction === 'rtl' ? 'md:pl-8' : 'md:pr-8',
              )}
            >
              {section.sectionTitle && (
                <Text className="mb-10 md:text-6xl text-4xl" variant="h3">
                  {section.sectionTitle}
                </Text>
              )}
              {section.sectionSubtitle && (
                <Text className="text-sm leading-6 mb-8">{section.sectionSubtitle}</Text>
              )}
              <Button
                className="uppercase text-xs"
                as={Link}
                href={section.cta.href}
                variant={section.cta.type}
              >
                {section.cta.label}
              </Button>
            </div>
          </div>
        )
      }

      if (section.type === 'product' && props[`products_${section.sort}`]) {
        return (
          <ProductList
            key={i}
            products={props[`products_${section.sort}`]}
            header={
              <ListHeader
                buttonLabel={section.cta?.label}
                showShowMoreButton={section.cta?.label}
                title={section.sectionTitle}
                href={section.cta.href}
                className={twMerge('mb-2', fluidClass)}
                subtitle={section.sectionSubtitle}
                titleProps={{ className: 'mb-3' }}
              />
            }
            className={fluidClass}
          />
        )
      }
      if (section.type === 'category' && props[`categories_${section.sort}`]) {
        return (
          <PopularCategories
            key={i}
            title={section?.sectionTitle}
            subtitle={section?.subtitle}
            mainCategories={props[`categories_${section.sort}`]}
            className={fluidClass}
          />
        )
      }
      if (section.type === 'shopCard' && props[`shops_${section.sort}`]) {
        return (
          <CardList
            key={i}
            title={section.sectionTitle}
            data={props[`shops_${section.sort}`]}
            className={fluidClass}
          />
        )
      }
      if (section.type === 'gallery') {
        return (
          <div key={i} className={fluidClass}>
            {section.sectionTitle && (
              <ListHeader
                buttonLabel={section.cta?.label}
                showShowMoreButton={section.cta?.label}
                title={section.sectionTitle}
                href={section.cta.href}
                subtitle={section.sectionSubtitle}
                className={twMerge('mb-0', fluidClass)}
              />
            )}
            <BannerLinksGallery key={i} banners={section.banners} />
          </div>
        )
      }
      if (section.type === 'gridBanner') {
        return (
          <div key={i} className={fluidClass}>
            {section.sectionTitle && (
              <ListHeader
                buttonLabel={section.cta?.label}
                showShowMoreButton={section.cta?.label}
                title={section.sectionTitle}
                href={section.cta.href}
                subtitle={section.sectionSubtitle}
                className="mb-4"
              />
            )}
            <GridLayout
              layouts={section.layouts}
              classNames={props[`classNames_${section.sort}`]}
              // rowHeight="150px"
              //className="min-h-80"
            >
              {section.banners.map(banner => (
                <BannerLink key={banner.sort} banner={banner} variant="gridCard" />
              ))}
            </GridLayout>
          </div>
        )
      }
      return null
    })}
  </div>
)

PageBanners.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      sort: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      banners: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
}

export default PageBanners
